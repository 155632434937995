<template>
  <div>
    <div class="container">
      <p>{{ displayMessage }}</p>
      <img v-if="isLoading" src="../assets/img/loader.gif" height="60" width="60">
      <alert-error v-if="error">
        <template v-slot:alert-error>
          {{msgError}}
        </template>
      </alert-error>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TikTokInstall',
  mounted() {
    document.title = 'Instalando o TikTok'

    if(!this.$route.query.state) {
      this.message = 'Houve um erro ao instalar o TikTok na sua loja.'
      this.loading = false
      return;
    }

    let storeId = this.$cookies.get('Authorization')
    let payload = this.getTikTokPayload(this.$route.query);

    this.sendTikTokPayload(payload, storeId);
  },
  data: function () {
    return {
      payload: null,
      message: 'Instalando o TikTok na sua loja.',
      loading: true,
      error: false,
      msgError: ""
    }
  },
  computed: {
    displayMessage: function () {
      return this.message
    },
    isLoading: function () {
      return this.loading;
    }
  },
  methods: {
    getTikTokPayload(query) {
      return {
        "auth_code": query.auth_code,
        "code": query.code,
        "state": query.state
      }
    },
    sendTikTokPayload(payload, storeId) {
      this.loading = true

      this.$http.get(
          'tiktok/auth/callback',
          {
            headers: {'Authorization': storeId},
            params: payload
          }
      ).then(() => {
        this.message = 'O TikTok foi instalado na sua loja.'
        this.loading = false
        opener.location = '/wizard#pixel'
        console.log('location do opener')
        window.setTimeout(function () {
          console.log('close')
          window.close()
        }, 2000)
      }).catch(error => {
        this.loading = false;
        let msg =  'Houve um erro ao instalar o TikTok na sua loja. ' + error.response.data.message;
        this.message  = msg;
        this.msgError = msg;
        this.error = true;
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 500px;
}
</style>
